import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { ClientColumnDataService } from './client-column-data.service';

export type DashboardBackground = 'globe-high' | 'globe-low' | 'plane';
export type ClientDisplayMode = 'grid' | 'list' | 'calendar';
export type ThemeSetting = 'light' | 'dark' | 'auto';

import * as MonacoType from 'monaco-editor';
import { CalendarView } from 'angular-calendar';
declare global {
  interface Window {
    monaco: typeof MonacoType | undefined;
  }
}

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  //temp data until IndexedDB comes about
  //localStorage blocks the main thread and is slow?

  // global
  @LocalStorage('global-sidebar-icons-only', false)
  globalSidebarIconsOnly!: boolean;
  @LocalStorage('global-notification-sound', true)
  globalNotificationSound!: boolean;
  @LocalStorage('global-theme', 'auto') globalTheme!: ThemeSetting;
  @LocalStorage('global-show-logs', false)
  globalShowLogs!: boolean;
  @LocalStorage('global-latest-version', null) globalLatestVersion!:
    | string
    | null;

  // home/dashboard
  @LocalStorage('dashboard-background', 'globe-high')
  dashboardBackground!: DashboardBackground;

  // list
  @LocalStorage('clients-display-mode', 'list')
  clientsDisplayMode!: ClientDisplayMode;
  @LocalStorage('clients-active-only', true) clientsActiveOnly!: boolean;
  @LocalStorage('clients-user-owned-only', false)
  clientsUserOwnedOnly!: boolean;
  @LocalStorage('clients-list-page-size', 10) clientsListPageSize!: number;
  @LocalStorage('clients-hide-columns', []) clientsHideColumns!: string[];
  @LocalStorage('clients-show-columns', []) clientsShowColumns!: string[];

  // map
  @LocalStorage('clients-map-pins-translucent', true)
  clientsMapPinsTranslucent!: boolean;

  // calendar
  @LocalStorage('clients-cal-view', CalendarView.Month)
  clientsCalView!: CalendarView;

  // client detail
  @LocalStorage('client-detail-show-overview', false)
  clientDetailShowOverview!: boolean;

  constructor(private clientColumnDataService: ClientColumnDataService) {
    // filter out columns that may have been removed and add new columns to "show" array
    let savedColumns = this.clientsShowColumns.concat(this.clientsHideColumns);
    let allColumns = this.clientColumnDataService.getColumnKeys();

    //cycle through existing columns (which filters out removed columns)
    this.clientsShowColumns = allColumns.filter((column) => {
      // column is new
      if (!savedColumns.includes(column)) {
        return true;
      }

      // column was previously saved
      if (this.clientsShowColumns.includes(column)) {
        return true;
      }

      return false;
    });

    // filter out removed "hidden" columns
    this.clientsHideColumns = allColumns.filter((column) => {
      // column was previously hidden
      if (this.clientsHideColumns.includes(column)) {
        return true;
      }
      return false;
    });

    this.setTheme(this.getCurrentTheme());
  }

  public setTheme(mode: ThemeSetting): Exclude<ThemeSetting, 'auto'> {
    let setMode: Exclude<ThemeSetting, 'auto'> = 'light';

    if (mode === 'auto') {
      const isDark =
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches;

      if (isDark) {
        setMode = 'dark';
      } else {
        setMode = 'light';
      }
    } else {
      setMode = mode;
    }

    if (setMode === 'dark') {
      document.documentElement.classList.add('dark');
      window.monaco?.editor.setTheme('monokai');
    } else {
      document.documentElement.classList.remove('dark');
      window.monaco?.editor.setTheme('vs');
    }

    return setMode;
  }

  public getCurrentTheme(): Exclude<ThemeSetting, 'auto'> {
    let currentTheme = this.globalTheme;

    if (currentTheme === 'auto') {
      const isDark =
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (isDark) {
        return 'dark';
      } else {
        return 'light';
      }
    }

    return currentTheme;
  }
}
